<template>
  <!-- 火车票面 -->
  <header class="head-background">
    <div class="headInfo">
      <van-row type="flex" justify="space-between" class="line1">
        <van-col>{{(ticket.trainBeginDate||ticket.planBeginDate) | comverTime('YYYY-MM-DD')}}</van-col>
        <van-col>{{ticket.runTime||ticket.runTimeSpan}}</van-col>
        <van-col>{{(ticket.trainEndDate||ticket.planEndDate) | comverTime('YYYY-MM-DD')}}</van-col>
      </van-row>
      <van-row type="flex" justify="space-between" class="line2">
        <van-col>
          <h3
            v-if="!Object.prototype.hasOwnProperty.call(ticket, 'startTime')"
          >{{ticket.planBeginDate | comverTime('HH:mm')}}</h3>
          <h3 v-if="Object.prototype.hasOwnProperty.call(ticket, 'startTime')">{{ticket.startTime}}</h3>
        </van-col>
        <van-col>
          <h3 class="time-line">
            <span class="left-line">-</span>
            <div @click="timetable=true">时刻表</div>
            <span class="right-line icon iconfont icon-icon_jipiaodancheng"></span>
          </h3>
        </van-col>
        <van-col>
          <h3
            v-if="!Object.prototype.hasOwnProperty.call(ticket, 'arriveTime')"
          >{{ticket.planEndDate | comverTime('HH:mm')}}</h3>
          <h3 v-if="Object.prototype.hasOwnProperty.call(ticket, 'arriveTime')">{{ticket.arriveTime}}</h3>
        </van-col>
      </van-row>
      <van-row type="flex" justify="space-between" class="line1">
        <van-col span="12" class="left">{{ticket.fromStationName||ticket.fromStation}}</van-col>
        <van-col class="cen">
          {{ticket.trainCode||ticket.trainNo}}
          <el-tooltip
            v-if="['C','G','D'].includes(ticket.trainClass)"
            class="item"
            effect="dark"
            content="可凭二代身份证直接进出站"
            placement="bottom-start"
            :hide-after="3000"
            @click.prevent.stop
          >
            <span class="iconfont icon-idCard"></span>
          </el-tooltip>
        </van-col>
        <van-col span="12" class="right">{{ticket.toStationName||ticket.toStation}}</van-col>
      </van-row>
    </div>

    <!-- 时刻表 -->
    <van-dialog v-model="timetable" title="经停信息">
      <van-row type="flex" justify="space-around" class="timetable">
        <van-col span="6">车站</van-col>
        <van-col span="6">到达</van-col>
        <van-col span="6">出发</van-col>
        <van-col span="6">停留</van-col>
      </van-row>
      <div class="timetable">
        <van-row
          type="flex"
          justify="space-around"
          v-for="(item,i) in stopList"
          :key="i"
          class="stop-station"
        >
          <van-col span="6">{{item.stationName}}</van-col>
          <van-col span="6">{{item.arriveTime}}</van-col>
          <van-col span="6">{{item.departTime}}</van-col>
          <van-col span="6">{{item.stayTime}}</van-col>
        </van-row>
      </div>
    </van-dialog>
  </header>
</template>

<script>
export default {
  name: "infoHead",
  props: {
    type: Object,
    ticket: {
      default: {
        toStationName: "",
        fromStationName: "",
        trainCode: "",
        arriveTime: "",
        startTime: "",
        trainEndDate: "",
        trainBeginDate: "",
        runTime: ""
      }
    },
    stopList: {
      type: Array
    }
  },
  data() {
    return {
      timetable: false
    };
  }
};
</script>

<style lang="less" src="@/styles/orderHeadCommon.less" scoped></style>
<style lang="less" scoped>
@import '../../../styles/theme.less';
.icon-idCard {
  color: #fff;
}
.stop-station {
  font-size: @font-size-sm;
  line-height: 22px;
}
.stop-station,
.timetable {
  text-align: center;
  max-height: 50vh;
  overflow: scroll;
}
.time-line {
  line-height: 20px;
  div {
    font-size: @font-size-sm;
    line-height: 12px;
    border: 1px solid #fff;
    display: inline-block;
    padding: 4px 6px;
    border-radius: 17px;
  }
  span {
    transform: translateY(-2px) translateX(-3px);
    display: inline-block;
  }
  .left-line {
    padding-left: 4px;
    transform: scaleX(2.5) translateX(-2px);
    color: @gray-3;
    font-size: @font-size-max;
  }
  .right-line {
    transform: translateX(-1px);
  }
}
</style>